<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.USER") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="userSchema"
                :model="user"
                :options="formOptions"
              ></vue-form-generator>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import { GET_USER, SAVE_USER, UPDATE_USER } from "@/store/users.module";

import { GET_DATA } from "@/store/formselect.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingUsers"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      model: "users",
      tab: 0,
      isFormValid: false,
      user: {
        name: null,
        email: null,
        password: null,
        role: null,
        active: true
      },
      roles: [],
      userSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "text",
            label: "Email",
            model: "email",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "text",
            label: "Код за подпис",
            model: "sealCode",
            min: 4,
            max: 4,
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!",
              textTooBig: "Полето трябва да бъде максимум {1} знака !!!"
            })
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Потребители", route: "/users" },
        { title: current_page }
      ]);
    }, 300);
    this.getRoles();

    if (typeof this.$route.params.id !== "undefined") {
      this.getData();
    }
  },
  methods: {
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      let action =
        typeof this.$route.params.id !== "undefined" ? UPDATE_USER : SAVE_USER;

      vm.$store
        .dispatch(action, { id: this.$route.params.id, payload: vm.user })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "usersList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;

          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_USER, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.user = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "usersList" });
          }
        });
    },
    getRoles: function() {
      let vm = this;

      let payload = {
        roles: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.roles = data.data.roles;

            if (vm.isEdit == false) {
              let addPasswordField = {
                type: "input",
                inputType: "password",
                label: "Парола",
                model: "password",
                min: 8,
                required: true,
                validator: VueFormGenerator.validators.string.locale({
                  fieldIsRequired: "Полето е задължително !!!",
                  textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
                })
              };
              let addPasswordConfField = {
                type: "input",
                inputType: "password",
                label: "Потвърди паролата",
                model: "passwordConfirmation",
                min: 8,
                required: true,
                validator: VueFormGenerator.validators.string.locale({
                  fieldIsRequired: "Полето е задължително",
                  textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
                })
              };

              vm.userSchema.fields.push(addPasswordField);
              vm.userSchema.fields.push(addPasswordConfField);
            }

            let addRoleField = {
              type: "select",
              label: "Роля",
              model: "roleId",
              required: true,
              class: "form-control",
              selectOptions: { noneSelectedText: "избери" },
              hideNoneSelectedText: true,
              values: vm.roles,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            vm.userSchema.fields.push(addRoleField);

            let activeField = {
              type: "checkbox",
              label: "Активен",
              model: "active",
              required: true
            };

            vm.userSchema.fields.push(activeField);

            // vm.userSchema.fields.push(addRoleField);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
        });
    }
  }
};
</script>
